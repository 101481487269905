:root {
  font-size: 62.5%;
  --body-padding: 5%;
  --body-paddingAlt: 20%;
}

@media screen and (max-width: 1000px) {
  :root {
    --body-padding: 4%;
    --body-paddingAlt: 10%;
  }
}
@media screen and (max-width: 700px) {
  :root {
    --body-padding: 3%;
    --body-paddingAlt: 5%;
  }
}

@media screen and (max-width: 500px) {
  :root {
    --body-paddingAlt: 3%;
  }
}

@import "abstracts/variable";

@import "components/tweetModal";
@import "components/music";
@import "components/heroBg";
@import "components/hero";
@import "components/about";
@import "components/contact";
@import "components/footer";
@import "components/hallOfFame";

//   @import "pages/notFound";

*,
::before,
::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: $text-color;
  background-color: $primary-color;
  width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: $text-color;
}

img {
  max-width: 100%;
  height: auto;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-color;
}

.svgTitle {
  width: 1343px;

  @media screen and (max-width: 550px) {
    width: 900px;
  }
}

.logo {
  font-family: "Bari Sans", sans-serif;
  display: inline-block;
  filter: drop-shadow(0px 0px 8px #ffffff) drop-shadow(0px 2px 32px #ffffff)
    drop-shadow(0px 0px 60px rgba(255, 255, 255, 0.4));
  font-weight: 500;
  font-size: 24px;
  cursor: default;
  color: #ffffff;
}
.logoOpt {
  font-family: "Bari Sans", sans-serif;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 9.29032px #ffffff, 0px 0px 9.29032px #ffffff,
    0px 2.32258px 37.1613px #ffffff, 0px 0px 69.6774px rgba(255, 255, 255, 0.4);
  cursor: default;
}

.logoAlt {
  display: block;
  font-family: "Bari Sans", sans-serif;
  line-height: 0;
  font-size: 150px;
  color: #ffffff;
  text-shadow: 0px 0px 9.29px #ffffff;
  position: relative;
  // animation: glowingLogo 4s linear infinite;
  cursor: default;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: 80px;
  }

  &.small {
    font-size: 55px;
  }
}

.logoAlt::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "$ratio";
  opacity: 0;
  text-shadow: 0px 2.32px 37.16px #ffffff, 0px 0px 9.29px #ffffff,
    0px 0px 9.29px #ffffff;
  animation: border-pulsate 4s infinite;
}
@keyframes border-pulsate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes glowingLogo {
  0% {
    text-shadow: 0px 0px 9.29px #ffffff;
  }
  20% {
    text-shadow: 0px 0px 9.29px #ffffff, 0px 0px 9.29px #ffffff;
  }
  40% {
    text-shadow: 0px 2.32px 37.16px #ffffff, 0px 0px 9.29px #ffffff,
      0px 0px 9.29px #ffffff;
  }
  50% {
    box-shadow: 0px 0px 69.68px #ffffff66, 0px 2.32px 37.16px #ffffff,
      0px 0px 9.29px #ffffff, 0px 0px 9.29px #ffffff;
  }
  60% {
    text-shadow: 0px 2.32px 37.16px #ffffff, 0px 0px 9.29px #ffffff,
      0px 0px 9.29px #ffffff;
  }
  80% {
    text-shadow: 0px 0px 9.29px #ffffff, 0px 0px 9.29px #ffffff;
  }
  100% {
    text-shadow: 0px 0px 9.29px #ffffff;
  }
}

.btn {
  width: 170px;
  height: 60px;
  border: none;
  outline: none;
  color: #fff;
  background: #000;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 72px;
  font-size: 24px;
  position: relative;

  @media screen and (max-width: 700px) {
    width: 150px;
    height: 45px;
    font-size: 18px;
  }
  @media screen and (max-width: 550px) {
    width: 120px;
    height: 35px;
    font-size: 16px;
  }
}

.btn:before {
  content: "";
  background: linear-gradient(
    45deg,
    #fff,
    #d0d0d0,
    #1d1d1d,
    #7a7a7a,
    #5a5a5a,
    #fff
  );
  position: absolute;
  top: -1px;
  left: -1px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glow 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 72px;
}

.btn:active {
  color: #000;
}

.btn:active:after {
  background: transparent;
}

// .btn:hover:before {
//   opacity: 1;
// }

.btn:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  left: 0;
  top: 0;
  border-radius: 72px;
}

@keyframes glow {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

//////////// MODAL ////////////////

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 50;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
}

.ReactModal__Content {
  &:focus-visible {
    outline: none !important;
  }
}
