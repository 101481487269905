.tweetModal {
  background: url(../../images/bgImage.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px var(--body-padding);

  &__logoCon {
    display: flex;
    flex-direction: column;
    // gap: 30px;
    margin-bottom: 30px;

    & img {
      width: 120px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 800px) {
      width: 100%;
    }

    &__tweets {
      position: relative;
      border: 1.3px solid #77777752;
      box-shadow: 0px 4px 13.9px 0px #ffffff0d;
      width: 700px;
      border-radius: 20.85px;
      backdrop-filter: blur(8px);
      background-color: #0000004b;
      padding: 30px;
      font-size: 18px;

      @media screen and (max-width: 800px) {
        width: 100%;
        padding: 20px;
      }

      &__item {
        display: flex;
        gap: 15px;
        margin-bottom: 3px;

        &__left {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;

          & img {
            width: 64px;
            height: 64px;
            object-fit: cover;

            @media screen and (max-width: 700px) {
              width: 50px;
              height: 50px;
            }
            @media screen and (max-width: 500px) {
              width: 36px;
              height: 36px;
            }
          }

          &__line {
            width: 2px;
            height: calc(100% - 64px);
            background-color: #353537;

            @media screen and (max-width: 700px) {
              height: calc(100% - 50px);
            }
          }
        }

        &__right {
          width: calc(100% - 79px);
          @media screen and (max-width: 700px) {
            width: calc(100% - 50px);
          }
          @media screen and (max-width: 500px) {
            width: calc(100% - 36px);
          }

          &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            margin-bottom: 10px;

            &__left {
              display: flex;
              align-items: center;
              gap: 5px;
              @media screen and (max-width: 700px) {
                font-size: 13px;
              }
              @media screen and (max-width: 500px) {
                font-size: 11px;
              }

              & p {
                color: #727274;
              }

              &__img {
                @media screen and (max-width: 700px) {
                  width: 13px;
                }
                // @media screen and (max-width: 500px) {
                //   width: 13px;
                // }
              }
            }

            &__right {
              & img {
                width: 15px;
              }
            }
          }

          &__tweet {
            margin-bottom: 15px;
            color: #cacaca;

            @media screen and (max-width: 700px) {
              font-size: 13px;
            }
            @media screen and (max-width: 500px) {
              font-size: 11px;
            }
          }

          &__actions {
            @media screen and (max-width: 700px) {
              font-size: 13px;
            }

            &.space {
              margin-bottom: 30px;
            }
            display: flex;
            justify-content: space-between;
            gap: 10px;

            &__item {
              display: flex;
              align-items: center;
              //   gap: 10px;
              color: #777777;

              & img {
                margin-right: 10px;

                @media screen and (max-width: 700px) {
                  width: 15px;
                }
              }

              & span {
                margin-right: 10px;
                max-width: 25px;
                font-size: 25px;
                @media screen and (max-width: 700px) {
                  font-size: 18px;
                }
              }

              &.ratio {
                color: #ff1180;
              }

              &.last {
                &:hover {
                  color: #ff1180;
                }
              }

              //   &__num {
              //     height: 30px;
              //     overflow: hidden;
              //     position: relative;
              //     width: 30px;

              //     @media screen and (max-width: 700px) {
              //       height: 18px;
              //     }

              //     &__main {
              //       position: absolute;
              //       top: 1px;
              //       transition: ease-in-out 1s;
              //       @media screen and (max-width: 700px) {
              //         top: -1px;

              //         & p {
              //           //   margin-bottom: 10px;
              //         }
              //       }

              //       & img {
              //         width: 20px;
              //         // @media screen and (max-width: 700px) {
              //         //   width: 10px !important;
              //         // }
              //       }

              //       &.active {
              //         color: #ff1180;
              //         top: -175%;

              //         @media screen and (max-width: 700px) {
              //           top: -200%;
              //         }
              //       }
              //     }
              //   }

              .glow {
                cursor: pointer;
                transition: 0.3s;
                animation: glowing 3s linear infinite;

                &:hover {
                  color: #ff1180;
                  animation: none;
                }
              }
            }
          }
        }
      }
    }

    &__info {
      margin-top: 50px;
      text-align: center;
      //   margin-bottom: 20px;

      @media screen and (max-width: 700px) {
        font-size: 13px;
      }

      @media screen and (max-width: 500px) {
        margin-top: 20px;
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      & button {
        transition: ease-in-out 0.5s;
        // transition-delay: 1s;
        position: relative;
        left: 250px;
        opacity: 0;

        &.show {
          left: 0;
          opacity: 1;
        }
      }
    }
  }
}

@keyframes glowing {
  0% {
    // text-shadow: 0 0 0px #fff;
    color: #777777;
  }
  10% {
    text-shadow: 0 0 2px #fff;
  }
  20% {
    text-shadow: 0 0 2px #fff, 0 0 3px #fff;
  }
  30% {
    text-shadow: 0 0 2px #fff, 0 0 5px #fff;
    color: #fff;
  }
  50% {
    text-shadow: 0 0 2px #fff, 0 0 10px #fff;
  }
  70% {
    text-shadow: 0 0 2px #fff, 0 0 5px #fff;
    color: #fff;
  }
  80% {
    text-shadow: 0 0 2px #fff, 0 0 3px #fff;
  }
  90% {
    text-shadow: 0 0 2px #fff;
  }
  100% {
    // text-shadow: 0 0 0px #fff;
    color: #777777;
  }
}
