.contact {
  padding: 100px var(--body-paddingAlt);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
  overflow: hidden;

  &__main {
    width: 100%;

    &__add {
      display: flex;
      // gap: 10px;
      position: relative;

      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
      }

      &__disc {
        display: flex;
        align-items: center;
        // gap: 20px;
        border: 1px solid #ffffff1f;
        border-radius: 16px;
        font-size: 24px;
        background-color: #ffffff09;
        backdrop-filter: blur(30px);
        padding: 15px 25px;
        width: calc(100% - 82px);
        margin-right: 10px;

        &__ca {
          margin-right: 20px;
          @media screen and (max-width: 1100px) {
            margin-right: 0;
          }
        }

        @media screen and (max-width: 1400px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1200px) {
          font-size: 18px;
        }

        @media screen and (max-width: 1100px) {
          flex-direction: column;
        }

        @media screen and (max-width: 600px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }

        &__main {
          display: flex;
          align-items: center;

          @media screen and (max-width: 750px) {
            flex-direction: column;
          }
        }
      }

      &__copy {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ffffff1f;
        border-radius: 22px;
        background-color: #ffffff09;
        width: 72px;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          height: 50px;
          width: 50px;
          border-radius: 15px;

          & img {
            width: 20px;
          }
        }
      }

      &__copied {
        position: absolute;
        right: 0;
        top: -25px;
        color: #ffffff;
        font-size: 13px;
      }
    }

    &__btns {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 20px;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
      & a {
        border: 5px solid transparent;
        background-color: rgb(255, 255, 255, 0.161);
        border-radius: 17.36px;

        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }

      & button {
        height: 64.39px;
        border-radius: 17.36px;
        padding: 0 20px;
        font-weight: 700;

        @media screen and (max-width: 500px) {
          width: 100%;
          height: 40px;
        }
      }

      &__anc {
        margin-right: 20px;
        @media screen and (max-width: 500px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      &__buy {
        background-color: #ffffff;
        color: #07072b;
        border: none;
      }

      &__join {
        background-color: #ffffff3e;
        color: #ffffff;
        border: 1.08px solid #ffffff5c;
      }
    }
  }
}
