.hallOfFame {
  overflow: hidden;

  &__main {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  &__img {
    width: 100%;
    border: 5px solid #ffffff29;
    border-radius: 20px;
    height: 400px;
    // object-fit: cover;
    @media screen and (max-width: 1100px) {
      height: 350px;
    }
    // @media screen and (max-width: 1100px) {
    //   height: 250px;
    // }
  }

  &__slide {
    width: 100%;
    padding-left: var(--body-padding);
    // background-image: url(../../images/svg/ellipse.svg);
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
    padding-bottom: 200px;
  }
}
