.hero {
  position: relative;
  min-height: 900px;

  @media screen and (max-width: 500px) {
    min-height: 700px;
    overflow: hidden;
  }

  &__main {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    align-items: center;

    &__top {
      width: 100%;
      padding: 40px var(--body-padding);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      &__right {
        display: flex;
        align-items: center;

        &__tel {
          margin-right: 40px;
        }

        & span {
          font-family: "Bari Sans", sans-serif;
          display: inline-block;
          filter: drop-shadow(0px 0px 8px #ffffff)
            drop-shadow(0px 2px 32px #ffffff)
            drop-shadow(0px 0px 60px rgba(255, 255, 255, 0.4));
          font-weight: 500;
          font-size: 24px;

          color: #ffffff;
        }
      }
    }

    &__center {
      display: flex;
      align-items: center;
      // justify-content: center;
      flex-direction: column;

      &__title {
        margin-bottom: 120px;

        @media screen and (max-width: 500px) {
          margin-bottom: 100px;
        }
      }
      & p {
        font-size: 24px;
        margin-top: 50px;
        color: #ffffff;
        font-weight: 300;
        text-align: center;
        margin-bottom: 40px;

        @media screen and (max-width: 700px) {
          font-size: 20px;
        }
      }

      // &__btn {
      //   // display: flex;
      //   // justify-content: center;
      // }
    }
    &__bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      //   gap: 5px;
      margin-bottom: 20px;

      & p {
        font-size: 24px;
        @media screen and (max-width: 700px) {
          font-size: 20px;
        }
      }

      & img {
        animation: downA 2s ease-in-out infinite;
      }
    }
  }
}

@keyframes downA {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}
