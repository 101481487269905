.heroBg {
  //   display: grid;
  //   grid-template-columns: repeat(5, 1fr);
  //   gap: 40px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(1, 1, 37, 0.69) 0%,
    rgba(2, 2, 41, 0.803801) 80.85%,
    #050530 95.02%
  );

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(1, 1, 37, 0.69) 0%,
      rgba(2, 2, 41, 0.803801) 80.85%,
      #050530 95.02%
    );
    z-index: 2;
  }

  &__column {
    width: 22%;
    display: flex;
    flex-direction: column;
    // gap: 40px;
    position: absolute;
    top: 0;
    animation: infiniteScrollBg 150s linear infinite;
    -webkit-animation: infiniteScrollBg 150s linear infinite;
    left: -2%;
    @media screen and (max-width: 1000px) {
      width: 27%;
    }
    @media screen and (max-width: 700px) {
      width: 36%;
    }
    @media screen and (max-width: 500px) {
      width: 50%;
      left: -20%;
    }

    &.two {
      left: 19%;
      @media screen and (max-width: 1000px) {
        left: 24%;
      }
      @media screen and (max-width: 700px) {
        left: 32%;
      }
      @media screen and (max-width: 500px) {
        left: 25%;
      }
    }
    &.three {
      left: 39%;
      @media screen and (max-width: 1000px) {
        left: 50%;
      }
      @media screen and (max-width: 700px) {
        left: auto;
        right: -2%;
      }
      @media screen and (max-width: 500px) {
        left: auto;
        right: -20%;
      }
    }
    &.forth {
      left: 59%;
      @media screen and (max-width: 1000px) {
        left: auto;
        right: -2%;
      }
      @media screen and (max-width: 700px) {
        display: none;
      }
    }
    &.last {
      left: auto;
      right: -2%;
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }

    &.alt {
      bottom: 0;
      top: auto;
      animation: infiniteScrollBgAlt 150s linear infinite;
      -webkit-animation: infiniteScrollBgAlt 150s linear infinite;
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;

      & img {
        // height: 500px;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }
}

@keyframes infiniteScrollBg {
  0% {
    // top: 0;
    transform: translateY(0px);
  }
  50% {
    // top: calc(-1316px * 5);
    transform: translateY(calc(-1316px * 5));
  }
  100% {
    // top: 0;
    transform: translateY(0px);
  }
}

@keyframes infiniteScrollBgAlt {
  0% {
    // bottom: 0;
    transform: translateY(0px);
  }
  50% {
    // bottom: calc(-1316px * 5);
    transform: translateY(calc(1316px * 5));
  }
  100% {
    // bottom: 0;
    transform: translateY(0px);
  }
}
