.footer {
  box-shadow: inset 0 0 0 1000px #050530cc;
  min-height: 640px;
  background-image: url(../../images/bgImage.png);
  padding: 40px var(--body-padding);
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__logoCon {
    margin-top: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    // gap: 50px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__right {
      display: flex;
      align-items: center;
      // gap: 20px;
    }
  }
}
