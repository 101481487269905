.music {
  position: fixed;
  z-index: 60;
  bottom: 70px;
  right: var(--body-padding);

  &__icon {
    border: 2.16px solid #ffffff1f;
    width: 69px;
    height: 69px;
    padding: 20px;
    border-radius: 25px;
    backdrop-filter: blur(8px);
    cursor: pointer;

    @media screen and (max-width: 700px) {
      width: 45px;
      height: 45px;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 18px;
    }

    & img {
      width: 39px;

      @media screen and (max-width: 700px) {
        width: 25px;
      }
    }
  }
}
