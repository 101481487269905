.about {
  padding: 100px var(--body-paddingAlt);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &__disc {
    width: 100%;
    padding: 30px;
    border: 1px solid #ffffff1f;
    border-radius: 16px;
    font-size: 24px;
    text-align: center;
    background-color: #ffffff09;
    backdrop-filter: blur(30px);
    margin-bottom: 30px;

    @media screen and (max-width: 550px) {
      font-size: 18px;
      padding: 20px 15px;
    }
  }

  &__embed {
    width: 100%;

    & iframe {
      width: 100%;
      min-height: 560px;
    }
  }
}
